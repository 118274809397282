import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/vercel/path0/apps/landing/src/components/providers.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/landing/src/scss/app.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/landing/src/scss/global.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@nordcom+nordstar-system@0.0.60_react-dom@19.0.0-rc-f65ac7bd-20240826_react@19.0.0-rc-f65ac7b_iznaedfhx7shkcdbftbix2n4r4/node_modules/@nordcom/nordstar-system/dist/nordstar-provider.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@nordcom+nordstar-view@0.0.60_react-dom@19.0.0-rc-f65ac7bd-20240826_react@19.0.0-rc-f65ac7bd-_6otz5khbk3273ltipbdgti2t74/node_modules/@nordcom/nordstar-view/dist/view.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/landing/src/components/footer.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.0-canary.159_@babel+core@7.24.7_@opentelemetry+api@1.9.0_babel-plugin-react-compile_dij4lezniz7jgt6xqdcroophmm/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.0-canary.159_@babel+core@7.24.7_@opentelemetry+api@1.9.0_babel-plugin-react-compile_dij4lezniz7jgt6xqdcroophmm/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/the-new-css-reset@1.11.3/node_modules/the-new-css-reset/css/reset.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.0-canary.159_@babel+core@7.24.7_@opentelemetry+api@1.9.0_babel-plugin-react-compile_dij4lezniz7jgt6xqdcroophmm/node_modules/next/font/google/target.css?{\"path\":\"src/utils/fonts.ts\",\"import\":\"Montserrat\",\"arguments\":[{\"weight\":\"variable\",\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-primary\",\"preload\":true}],\"variableName\":\"primaryFont\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.0-canary.159_@babel+core@7.24.7_@opentelemetry+api@1.9.0_babel-plugin-react-compile_dij4lezniz7jgt6xqdcroophmm/node_modules/next/font/local/target.css?{\"path\":\"../../node_modules/.pnpm/geist@1.3.1_next@15.0.0-canary.159_@babel+core@7.24.7_@opentelemetry+api@1.9.0_babel-plugin-r_d2spk5ahbv5bpj5idalxyeajau/node_modules/geist/dist/mono.js\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/geist-mono/GeistMono-Variable.woff2\",\"variable\":\"--font-geist-mono\",\"adjustFontFallback\":false,\"fallback\":[\"ui-monospace\",\"SFMono-Regular\",\"Roboto Mono\",\"Menlo\",\"Monaco\",\"Liberation Mono\",\"DejaVu Sans Mono\",\"Courier New\",\"monospace\"],\"weight\":\"100 900\"}],\"variableName\":\"GeistMono\"}");
